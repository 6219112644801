import { ref } from 'vue'

interface Order {
  order_id: number
  order_status_id: number
  delivery_time: string
  // Permet d'éviter les erreurs si d'autres propriétés existent
}

export const newVisibleOrder = ref<Order | null>(null)
