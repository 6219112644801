import { computed } from 'vue'
import stores from '../stores'

export const configWebMessageSpecialStatus = computed(() => {
  const setting = stores.state.storeData.setting?.find(
    (setting: { key: string }) => setting.key === 'config_web_message_special_status'
  )

  return setting?.value || 0
})
