import { orderGet } from '@/storage/order/orderGet'
import { computed } from 'vue'
import { updateMostRecentOrderClass, updateMostRecentOrderText } from './updateMostRecentOrder'
import { mostRecentOrderClass } from './mostRecentOrderClass'
import { mostRecentOrderText } from './mostRecentOrderText'

export const mostRecentOrder = computed({
  get() {
    if (!orderGet.value || orderGet.value.length === 0) {
      return null
    }

    const today = new Date()
    const todayString = today.toISOString().split('T')[0]

    const normalizeDate = (dateString: any) => {
      const match = dateString.match(/^(\d{4})\/(\d{2})\/(\d{2}) (\d{2})h(\d{2})$/)
      if (match) {
        const [_, year, month, day, hour, minute] = match
        return `${year}-${month}-${day}T${hour}:${minute}:00`
      }
      return dateString
    }

    const todayOrders = orderGet.value.filter((order) => {
      const normalizedDate = normalizeDate(order.date_added)
      const orderDate = new Date(normalizedDate)
      const orderDateString = orderDate.toISOString().split('T')[0]
      return orderDateString === todayString
    })

    if (todayOrders.length === 0) {
      return null
    }

    const mostRecent = todayOrders.reduce((mostRecent, currentOrder) => {
      const mostRecentDate = new Date(normalizeDate(mostRecent.date_added))
      const currentOrderDate = new Date(normalizeDate(currentOrder.date_added))
      return currentOrderDate > mostRecentDate ? currentOrder : mostRecent
    })

    if (mostRecent) {
      updateMostRecentOrderClass(mostRecent.order_status_id, mostRecentOrderClass)
      updateMostRecentOrderText(mostRecent.order_status_id, mostRecentOrderText)
    }
    return mostRecent
  },

  set(newStatusId) {
    const mostRecent: any = mostRecentOrder.value
    if (!mostRecent) {
      return
    }
    const orderStatusId = newStatusId?.order_status_id
    const deliveryTime = newStatusId?.delivery_time

    mostRecent.order_status_id = orderStatusId
    mostRecent.delivery_time = deliveryTime

    updateMostRecentOrderClass(mostRecent.order_status_id, mostRecentOrderClass)
    updateMostRecentOrderText(mostRecent.order_status_id, mostRecentOrderText)

    return mostRecent
  }
})
