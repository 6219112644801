export const injectCaptchaScript = (nonce: string) => {
  const script = document.createElement('script')
  script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js'
  script.type = 'text/javascript'
  script.nonce = nonce // Injecter le nonce ici
  script.async = true
  script.defer = true

  window.onloadTurnstileCallback = () => {
    if (window.turnstile && typeof window.renderCaptcha === 'function') {
      window.renderCaptcha() // Appel à la fonction qui rend le CAPTCHA
    }
  }

  document.head.appendChild(script)
}
