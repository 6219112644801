import { orderGet } from '@/storage/order/orderGet'
import { computed } from 'vue'
import { mostRecentOrder } from '../mostRecentOrder'
import { visibleCount } from '@/event/dashboard/visibleCount'
import { newVisibleOrder } from './newVisibleOrder'
import { selectedOrder } from '@/event/account/dashboard/history/selectedOrder'

export const visibleOrders = computed(() => {
  if (!orderGet.value || orderGet.value.length === 0) {
    return []
  }

  const recentOrderId = mostRecentOrder.value ? mostRecentOrder.value.order_id : null
  let newOrderGet = [...orderGet.value]

  if (newVisibleOrder.value && newVisibleOrder.value.order_id !== undefined) {
    const orderId = (newVisibleOrder.value as { order_id: number }).order_id

    const orderToUpdate = newOrderGet.find((order) => order.order_id === orderId)
    if (orderToUpdate) {
      orderToUpdate.order_status_id = newVisibleOrder.value.order_status_id
      orderToUpdate.delivery_time = newVisibleOrder.value.delivery_time
    }
  }

  return newOrderGet
    .filter((order) => order.order_id !== recentOrderId)
    .slice(0, visibleCount.value)
})
