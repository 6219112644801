import { isTokenExpired } from './isTokenExpired'
import { fetchRefreshToken } from './fetchRefreshToken'
import { isAuthenticated } from '@/event/account/auth/authentification'
import { isRefreshTokenExpired } from './isRefreshTokenExpired'
import { isNotAuthenticated } from '@/event/account/auth/isNotAuthenticated'
import { fetchUserWithToken } from './fetchUserWithToken'
import { formatStoreName } from '../store/formatStoreName'
import stores from '@/storage/stores'
import customer from '@/storage/customer'
import { waitAuhenticated } from '@/storage/account/waitAuthenticated'


export async function checkAndRefreshToken() {
  const store = stores.getStoresData().name

  const token = store
    ? sessionStorage.getItem(`${formatStoreName(store)}_jwt`)
    : sessionStorage.getItem('jwt')

  const refreshToken = store
    ? localStorage.getItem(`${formatStoreName(store)}_refresh_token`)
    : localStorage.getItem('refresh_token')

  if (token) {
    const isExpired = isTokenExpired(token)

    if (!isExpired) {
      let res
      if (customer.state.customerData.customer_id == undefined) {
        res = await fetchUserWithToken(token)
      }

      if (res) {
        isAuthenticated.value = true
        waitAuhenticated.value = 1
      }
    }

    if (isExpired) {
      if (refreshToken && !isRefreshTokenExpired(refreshToken) && isNotAuthenticated.value === 0) {
        await fetchRefreshToken(refreshToken)
      }
    }
  } else if (refreshToken && !isRefreshTokenExpired(refreshToken) && isNotAuthenticated.value === 0) {
    await fetchRefreshToken(refreshToken)
  }

  if (!token || isTokenExpired(token)) {
    waitAuhenticated.value = 1
  }
}
