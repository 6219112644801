// import stores from "@/storage/stores";

import stores from "@/storage/stores";


// export async function encryptPassword(password: string) {
//     const encoder = new TextEncoder();
//     const encodedData = encoder.encode(password);


//     const publicKey = stores.getStoresData().setting?.find((s: { key: string, value: string }) => s.key === 'publicKey')?.value;
//     const goodPublicKey = publicKey.replace(/(-----BEGIN PUBLIC KEY-----|-----END PUBLIC KEY-----|\n|\r)/g, '').trim() ?? null;

//     // Importer la clé publique
//     const importedKey = await window.crypto.subtle.importKey(
//         "spki",
//         base64ToArrayBuffer(goodPublicKey),
//         {
//             name: "RSA-OAEP",
//             hash: { name: "SHA-256" }
//         },
//         false,
//         ["encrypt"]
//     );

//     // Chiffrer les données
//     const encrypted = await window.crypto.subtle.encrypt(
//         { name: "RSA-OAEP" },
//         importedKey,
//         encodedData
//     );

//     const arrayEncrypted = arrayBufferToBase64(encrypted);
//     // Convertir le résultat en base64
//     return arrayEncrypted
// }


// function base64ToArrayBuffer(base64: string) {
//     const binary = atob(base64);
//     const len = binary.length;
//     const buffer = new Uint8Array(len);
//     for (let i = 0; i < len; i++) {
//         buffer[i] = binary.charCodeAt(i);
//     }
//     return buffer.buffer;
// }

// function arrayBufferToBase64(buffer: ArrayBuffer) {
//     const uint8 = new Uint8Array(buffer);
//     const binary = String.fromCharCode.apply(null, Array.from(uint8));

//     return btoa(binary);
// }

import JSEncrypt from 'jsencrypt';

export async function encryptPassword(password: string) {
    try {
        const publicKey = stores.getStoresData().setting?.find(
            (s: { key: string, value: string }) => s.key === 'publicKey'
        )?.value;

        if (!publicKey) {
            throw new Error("Clé publique non trouvée");
        }

        // Initialiser JSEncrypt
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);

        // Chiffrer avec PKCS#1 v1.5 (par défaut dans JSEncrypt)
        const encrypted = encrypt.encrypt(password);

        if (!encrypted) {
            throw new Error("Échec du chiffrement");
        }

        return encrypted; // JSEncrypt retourne déjà en base64

    } catch (error) {
        console.error("Erreur lors du chiffrement:", error);
        throw error;
    }
}