import { languageIdBus } from '@/event/languageIdBus'

export const getProductDescriptionAssociated = (product: {
  description: { language_id: number; description: string }[]
}) => {
  if (!product || !product.description) {
    return
  }

  const description = product.description.find(
    (desc: { language_id: number }) => desc.language_id === languageIdBus.value
  )

  return description && description.description.length > 0 ? description.description : ''
}
