import { ref } from 'vue'
import { messageDeleteAccount } from '../messageDeleteAccount'
import { messageAlert } from '@/event/alert/messageAlert'

export const showAlertDeleteAccount = ref(false)

export function toggleAlertDeleteAccount() {
  showAlertDeleteAccount.value = !showAlertDeleteAccount.value
  if (showAlertDeleteAccount.value === false) {
    messageDeleteAccount.value = messageAlert
  }
}
