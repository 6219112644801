import { arrayUrlTest, normalizedHost } from "./constants"

export const urlWebSocket = (() => {
    if (arrayUrlTest.includes(normalizedHost)) {
        return "https://api.eronor.eu:4000"
    } else {
        return "https://api.eronor.info:4000"
    }
})()

