import { url } from "@/event/url/url"

const hostname = url.host
export const normalizedHost = hostname.startsWith('www.') ? hostname.substring(4) : hostname;
// const parts = hostname.split('.')
const parts = normalizedHost.split('.')
const numPart = parts.length


export const arrayUrlTest = ['localhost:5173', 'dev.eronor.eu', 'menuboard.fr']

export const URL_BACK: string = (() => {
    if (arrayUrlTest.includes(normalizedHost)) {
        return 'back.eronor.eu'
    } else if (parts[numPart - 1] === 'fr') {
        return 'back.abikebab.fr'
    }

    return "default.backend.com";
})()

export const URL_IMAGE: string = `https://${URL_BACK}/`

