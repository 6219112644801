import { computed } from 'vue'
import stores from '../stores'

export const configWebMessageSpecial = computed(() => {
  let setting = stores.state.storeData.setting?.find(
    (setting: { key: string }) => setting.key === 'config_web_message_special'
  )

  //   if (!setting || !setting.valide) {
  //     return ''
  //   }

  //   let parsedSetting

  //   try {
  //     parsedSetting = JSON.parse(setting.value)
  //   } catch (error) {
  //     return ''
  //   }

  //   if (parsedSetting && Object.keys(parsedSetting).length > 0) {
  //     return parsedSetting
  //   } else {
  //     return ''
  //   }
  return setting?.value || ''
})
