export function statusOrder(orderStatus: number) {
  if (orderStatus === 1 || orderStatus === 12 || orderStatus === 9) {
    return { text: 'En attente', class: 'wait-class' }
  }

  if (orderStatus === 4) {
    return { text: 'Annulée', class: 'wait-class' }
  }

  if (orderStatus === 2) {
    return { text: 'Préparation', class: 'preparation-class' }
  }

  if (orderStatus === 5) {
    return { text: 'Approuvée', class: 'approve-class' }
  }

  if (orderStatus === 3) {
    return { text: 'Prêt', class: 'ready-class' }
  }

  if (orderStatus === 6) {
    return { text: 'Livrée', class: 'delivery-class' }
  }

  return { text: '', class: '' }
}
